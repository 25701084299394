import React from "react";
import posed from "react-pose";
import styled from "styled-components";

const Row = ({
  name = "name",
  score = 55,
  rank = 1,
  visible = false,
  index,
  animateIn,
  teamName,
  adminView = false
}) => {
  // const [animateIn, setAnimateIn] = React.useState(visible);
  return (
    <>
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1234.47 91.68">
        <defs>
          <linearGradient
            id="a"
            x1="617.24"
            y1="107.72"
            x2="617.24"
            y2="32.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#c6c6c6" />
            <stop offset="1" stopColor="#ededed" />
          </linearGradient>
          <linearGradient
            id="lightGrey"
            x1="617.24"
            y1="107.72"
            x2="617.24"
            y2="32.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#c6c6c6" />
            <stop offset="1" stopColor="#ededed" />
          </linearGradient>
          <linearGradient
            id="darkBlue"
            x1="617.24"
            y1="107.72"
            x2="617.24"
            y2="32.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#232b6a" />
            <stop offset="1" stopColor="#293179" />
          </linearGradient>
          <linearGradient
            id="lightBlue"
            x1="617.24"
            y1="107.72"
            x2="617.24"
            y2="32.49"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#238eba" />
            <stop offset="1" stopColor="#27a1d2" />
          </linearGradient>
        </defs>
        <g data-name="Layer 1">
          <g data-name="row">
            <TextRow
              name={name}
              animateIn={!animateIn}
              index={index}
              adminView={adminView}
              teamName={teamName}
            />
            <Score
              score={score}
              animateIn={!animateIn}
              index={index}
              adminView={adminView}
            />
            <Rank
              rank={rank}
              animateIn={!animateIn}
              index={index}
              adminView={adminView}
            />
          </g>
        </g>
      </Svg>
    </>
  );
};

export default Row;

const transition = {
  transition: {
    x: { ease: "easeInOut", duration: 300 },
    default: { ease: "easeInOut", duration: 300 }
  }
};

const Svg = styled.svg`
  padding-top: 20px;
`;

const GRank = posed.g({
  start: {
    x: -250,
    delay: ({ index }) => index * 300,
    transition
  },
  finish: {
    x: 0,
    delay: ({ index }) => index * 300,
    transition
  }
});

const Rank = ({ rank, animateIn, index, adminView }) => (
  <GRank data-name="rank" pose={animateIn ? "start" : "finish"} index={index}>
    <path
      fill="#2aace2"
      fillRule="evenodd"
      d="M189.53.02H-3.39v91.66h240.8L189.53.02z"
    />
    <path
      fill={adminView ? "url(#lightGrey)" : "url(#darkBlue)"}
      fillRule="evenodd"
      d="M169.67.02H-23.25v91.66h240.81L169.67.02z"
    />
    <text
      transform="translate(54.22 67.03)"
      fontSize="59.96"
      fill={adminView ? "#2c3582" : "#FFF"}
      fontFamily="Arial"
      fontWeight="700"
    >
      {rank}
    </text>
  </GRank>
);

const GScore = posed.g({
  start: { x: 310, delay: ({ index }) => index * 300, transition },
  finish: { x: 0, delay: ({ index }) => index * 300, transition }
});

const Score = ({ score, animateIn, index, adminView }) => (
  <GScore data-name="score" pose={animateIn ? "start" : "finish"} index={index}>
    <path
      fill={adminView ? "url(#lightGrey)" : "url(#darkBlue)"}
      fillRule="evenodd"
      d="M980.35 91.68h192.92V.02H932.46l47.89 91.66z"
    />
    <path
      fill="url(#lightBlue)"
      fillRule="evenodd"
      d="M1000.21 91.68h236.71V.02h-284.6l47.89 91.66z"
    />
    <text
      transform="translate(1044.53 67.03)"
      fontSize="59.96"
      fill="#fff"
      fontFamily="Arial"
      fontWeight="700"
    >
      {score}%
    </text>
  </GScore>
);

const GText = posed.g({
  start: { x: -1270, delay: ({ index }) => index * 300, transition },
  finish: { x: 0, delay: ({ index }) => index * 300, transition }
});

const TextRow = ({ name, animateIn, index, adminView, teamName }) => (
  <GText
    data-name="text-row"
    pose={animateIn ? "start" : "finish"}
    index={index}
  >
    <path
      fill={adminView ? "url(#darkBlue)" : "url(#lightGrey)"}
      // mixBlendMode="multiply"
      d="M-2.92.02h1240.31v91.66H-2.92z"
    />
    <text
      transform="translate(243.05 63.18)"
      fontSize="45.94"
      fill={adminView ? "#FFF" : "#2c3582"}
      fontFamily="Arial"
      fontWeight="700"
      letterSpacing=".05em"
    >
      {name}
    </text>
    {!adminView && (
      <g data-name="teamOne">
        <circle cx="907.7" cy="45.84" r="18.04" fill="#2c3582" />
        <text
          transform="translate(897.27 56.78)"
          fontSize="28"
          fill={adminView ? "#2c3582" : "#FFF"}
          fontFamily="Arial"
          // letterSpacing=".26em"
        >
          {teamName ? teamName.charAt(0) : ""}
        </text>
      </g>
    )}
  </GText>
);
