var firebaseConfig = {
  apiKey: "AIzaSyA8lubF8ecK6MRUlsWRe8ja5WoAQvfXLcY",
  authDomain: "zimmer-leaderboard.firebaseapp.com",
  databaseURL: "https://zimmer-leaderboard.firebaseio.com",
  projectId: "zimmer-leaderboard",
  storageBucket: "zimmer-leaderboard.appspot.com",
  messagingSenderId: "28098116350"
};

export default firebaseConfig;
