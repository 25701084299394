import React from "react";
import styled, { keyframes } from "styled-components";
import LandscapeView from "./LandscapeView";
import Background from "../../assets/background.png";
import { PortraitView } from "./PortraitView";

const Leaderboard = ({ adminView }) => {
  const [isPortrait, setIsPortrait] = React.useState(null);

  React.useEffect(() => {
    setIsPortrait(window.innerWidth < window.innerHeight);
  }, []);

  return (
    <>
      {isPortrait === null ? (
        <LoaderComponent />
      ) : isPortrait ? (
        <PortraitView adminView={adminView} />
      ) : (
        <LandscapeView adminView={adminView} />
      )}
    </>
  );
};

export default Leaderboard;

const LoaderComponent = () => (
  <LoadingWrap>
    <Loader />
  </LoadingWrap>
);

const LoadingWrap = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const spin = keyframes`
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }`;

const Loader = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: ${spin} 2s linear infinite; /* Safari */
  animation: ${spin} 2s linear infinite;
`;

export const Wrap = styled.div`
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  background: url(${Background});
`;

export const AdminOverlay = styled.div`
  ${({ adminView }) =>
    adminView
      ? `background: #272f73cc;
        min-height: 80vh;`
      : ""}
`;
