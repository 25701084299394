import React from "react";
import firebase from "../firebase";

function useConsultants(board = "sales") {
  const [consultants, setConsultants] = React.useState([]);
  React.useEffect(() => {
    return getConsultants();
  }, []);
  const getConsultants = () =>
    firebase.db
      .collection(`boards/${board}/consultants`)
      .orderBy("budget", "desc")
      .onSnapshot(handleSnapshot);

  const handleSnapshot = snapshot => {
    const consultants = snapshot.docs.map((doc, i) => formatDoc(doc, i));
    setConsultants(consultants);
  };

  const formatDoc = (doc, i) => ({ id: doc.id, rank: i + 1, ...doc.data() });

  return [consultants];
}

export { useConsultants };
