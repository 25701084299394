import React from "react";
import firebase from "../firebase";
import { Button, Dropdown, Modal } from "semantic-ui-react";
import { Form } from "semantic-ui-react";

const friendOptions = [
  {
    key: "Gladiators",
    text: "Gladiators",
    value: "Gladiators",
    image: { avatar: true, src: "/images/avatar/small/jenny.jpg" }
  },
  {
    key: "Bone Collectors",
    text: "Bone Collectors",
    value: "Bone Collectors",
    image: { avatar: true, src: "/images/avatar/small/elliot.jpg" }
  },
  {
    key: "Delta Force",
    text: "Delta Force",
    value: "Delta Force",
    image: { avatar: true, src: "/images/avatar/small/stevie.jpg" }
  },
  {
    key: "Legends",
    text: "Legends",
    value: "Legends",
    image: { avatar: true, src: "/images/avatar/small/christian.jpg" }
  },
  {
    key: "Seal Team",
    text: "Seal Team",
    value: "Seal Team",
    image: { avatar: true, src: "/images/avatar/small/christian.jpg" }
  }
];
export const EditModal = ({
  isOpen,
  handleClose,
  editing,
  board = "sales"
}) => {
  const [name, setName] = React.useState(editing.name);
  const [teamName, setTeamName] = React.useState(editing.teamName);
  const [budget, setBudget] = React.useState(editing.budget);
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    setName(editing.name);
    setBudget(editing.budget);
    setTeamName(editing.teamName);
  }, [editing]);

  const handleSubmit = event => {
    event.preventDefault();
    updateConsultant({ name, teamName, budget });
  };

  const updateConsultant = async ({ name, teamName, budget }) => {
    setSubmitting(true);

    if (editing.id) {
      await firebase.db
        .collection(`boards/${board}/consultants`)
        .doc(editing.id)
        .set({ name, teamName, budget: parseInt(budget) });
    } else {
      firebase.db
        .collection(`boards/${board}/consultants`)
        .add({ name, teamName, budget: parseInt(budget) });
    }

    setSubmitting(false);
    handleClose();
  };

  console.log(teamName);
  return (
    <Modal open={isOpen} onClose={handleClose} basic size="tiny">
      <Modal.Header>Edit Consultant</Modal.Header>
      <Modal.Content>
        <Form onSubmit={handleSubmit} loading={submitting}>
          <Form.Field>
            <label>Name</label>
            <input
              placeholder="First Name"
              value={name}
              onChange={event => {
                setName(event.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Budget Δ</label>
            <input
              placeholder="100"
              type="number"
              value={budget}
              onChange={event => {
                setBudget(event.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Last Name</label>
            <Dropdown
              placeholder="Select Friend"
              fluid
              selection
              options={friendOptions}
              onChange={(e, { value }) => {
                setTeamName(value);
              }}
              value={teamName}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={handleClose}>
          Cancel
        </Button>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="Save"
          onClick={handleSubmit}
          disabled={
            (name && name.length < 3) ||
            (budget && budget.length === 0) ||
            !teamName
          }
        />
      </Modal.Actions>
    </Modal>
  );
};
