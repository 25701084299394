import React from "react";
import styled from "styled-components";

const Footer = ({ adminView }) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1234.47 96.2"
      adminView={adminView}
    >
      <defs>
        <radialGradient
          id="a-footer"
          cx="12.12"
          cy="49.83"
          fx="-5.669"
          r="215.77"
          gradientTransform="matrix(1 0 0 1.06 0 -3.09)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#00a6e1" />
          <stop offset=".91" stopColor="#273284" />
        </radialGradient>
        <linearGradient
          id="b-footer"
          x1="136.61"
          y1="92.42"
          x2="405.45"
          y2="92.42"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".23" stopColor="#2aace2" stopOpacity=".9" />
          <stop offset=".85" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
      <g data-name="Layer 1">
        <g data-name="footer">
          <g data-name="Zimmer Biomet long white" fill="#2c3582">
            <path d="M601.63 59.44l13.55-21.08h-12.35V36h16.37v.44L606 57.43h13v2.48h-17.37zM623.78 36h2.91v23.91h-2.91zm9.1 0h4l7.39 18.64L651.48 36h4v23.91h-2.92V39.76l-7.92 20.15h-.91l-8.2-20.15v20.15h-2.67zm28.63 0h4L673 54.62 680.12 36h4v23.91h-2.91V39.76l-7.93 20.15h-.9l-8.2-20.15v20.15h-2.68zm28.64 0h14v2.38h-11v8.13H702v2.38h-8.9v8.66h11.65v2.38h-14.6zm18.94 0h7.19c4.58 0 8.37 1.88 8.37 6.66v.14c0 3.95-2.65 5.89-5.93 6.52l7.33 10.61h-3.24l-7.13-10.43H712v10.41h-2.91zm7.39 11.21c3.41 0 5.25-1.47 5.25-4.41v-.14c0-3.24-2-4.31-5.25-4.31H712v8.86zM739 36h7.2c4.45 0 7.69 1.48 7.69 6.19v.14a4.9 4.9 0 0 1-3.61 5c3.48.84 4.78 2.61 4.78 5.72v.14c0 4.51-3.08 6.69-7.86 6.69H739zm7.2 10.44c3.51 0 4.85-1.27 4.85-4.21v-.14c0-2.71-1.57-3.81-4.92-3.81h-4.28v8.16zm.93 11.21c3.48 0 5.09-1.54 5.09-4.45v-.13c0-2.94-1.64-4.35-5.49-4.35h-4.88v8.93zM759.94 36h2.91v23.91h-2.91zm7.93 12.12v-.27c0-6.79 4.75-12.15 11.78-12.15s11.67 5.3 11.67 12.06V48c0 6.76-4.65 12.18-11.61 12.18s-11.84-5.42-11.84-12.11m20.44-.1v-.27c0-5.35-3.14-9.7-8.66-9.7s-8.77 4.08-8.77 9.77v.26c0 5.79 3.85 9.74 8.83 9.74 5.32 0 8.6-4 8.6-9.8m7.86-12h4.05l7.39 18.64L814.77 36h4v23.91h-2.91V39.76L808 59.91h-.9l-8.2-20.15v20.15h-2.68zm28.64 0h14v2.38h-11v8.13h8.9v2.38h-8.9v8.66h11.64v2.38h-14.64zm23.85 2.38h-7V36h16.83v2.38h-7v21.53h-2.91z" />
            <path
              d="M560.79 19.74a29.48 29.48 0 1 1-29.48 29.48c0-17.58 11.9-29.48 29.48-29.48m-20 48.39l19.91-37.81h-.19a18.85 18.85 0 0 0-18.74 17.23v-18H580a27.42 27.42 0 0 0-19.17-7.78c-16.48 0-27.5 11-27.5 27.5a27.45 27.45 0 0 0 7.52 18.91m.49.48A27.5 27.5 0 0 0 580.45 30l-19.72 37.83a18.79 18.79 0 0 0 18.39-16.23v17z"
              fillRule="evenodd"
            />
          </g>
        </g>
        <path
          fill="url(#a-footer)"
          d="M.69-1.41l276.15.95-63.59 104.59H.69V-1.41z"
        />
        <path
          fill="url(#b-footer)"
          d="M120.59 79.83l295.71.34v24.84H107.44l13.15-25.18z"
        />
      </g>
    </Svg>
  );
};

export default Footer;

const Svg = styled.svg`
  ${({ adminView }) => (!adminView ? " padding-top: 20px;" : "")}
`;
