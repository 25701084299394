import React from "react";
import HeaderLandscape from "./Header/HeaderLandscape";
import RowLandscape from "./Row/RowLandscape";
import Pump from "./Pump/Pump";
import styled from "styled-components";
import { useAnimatedConsultantList } from "./useAnimatedConsultantList";
import sales from "../../assets/sales-landscape.jpg";
import account from "../../assets/account-landscape.jpg";

const LandscapeView = ({ adminView }) => {
  const [visibleItems, animateIn, animationDelay] = useAnimatedConsultantList(
    adminView ? "account-managers" : "sales",
    7,
    400,
    10000
  );

  return (
    <>
      {/* <HeaderLandscape
        title={!adminView ? "SALES" : "ACCOUNT"}
        subTitle={!adminView ? "LEADERBOARD" : "MANAGERS"}
        adminView={adminView}
      /> */}
      <img src={adminView ? account : sales} style={{ width: "100%" }} />
      <Wrap>
        {visibleItems.length > 0
          ? visibleItems.map((item, i) => (
              <RowLandscape
                name={item.name}
                score={item.budget}
                rank={item.rank}
                index={i}
                teamName={item.teamName}
                visible={false}
                key={item.id}
                animateIn={animateIn}
                adminView={adminView}
                animationDelay={animationDelay}
              />
            ))
          : null}
        <StyledPump>
          <Pump />
        </StyledPump>
      </Wrap>
    </>
  );
};

export default LandscapeView;

const Wrap = styled.div`
  position: relative;
`;

const StyledPump = styled.div`
  position: absolute;
  right: 7px;
  top: -10vh;
  width: 21vw;
`;
