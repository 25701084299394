import React from "react";
import { useConsultants } from "../Admin/useConsultants";
import { useInterval } from "./useInterval";

export function useAnimatedConsultantList(
  collection,
  itemsShown,
  animationDelay,
  timeOnScreen
) {
  const [visibleItems, setVisibleItems] = React.useState([]);
  const [consultants] = useConsultants(collection);
  const [step, setStep] = React.useState(1);
  const [totalSteps, setTotalSteps] = React.useState();
  const [animateIn, setAnimateIn] = React.useState(false);
  const [initial, setInital] = React.useState(true);

  useInterval(() => {
    if (consultants.length > 1) {
      setAnimateIn(false);
      setTimeout(swapConsultants, animationDelay * itemsShown);
      setTimeout(() => {
        setAnimateIn(true);
      }, 3250);
    }
  }, timeOnScreen);

  const swapConsultants = React.useCallback(
    totalStepsArg => {
      const items = consultants.slice(
        (step - 1) * itemsShown,
        step * itemsShown
      );
      const tStep = totalStepsArg ? totalStepsArg : totalSteps;

      setVisibleItems(items);
      setStep(tStep === 0 || tStep === step ? 1 : step + 1);
    },
    [setVisibleItems, consultants, setStep, step, totalSteps, itemsShown]
  );

  React.useEffect(() => {
    if (consultants.length) {
    }
  }, [consultants, itemsShown]);

  React.useEffect(() => {
    if (initial && consultants.length) {
      const totalSteps = Math.ceil(consultants.length / itemsShown);
      setTotalSteps(totalSteps);
      setTimeout(() => {
        swapConsultants(totalSteps);
      }, 500);
      setTimeout(() => {
        setAnimateIn(true);
      }, 900);
      setInital(false);
    }
  }, [
    consultants,
    swapConsultants,
    initial,
    setStep,
    setTotalSteps,
    itemsShown
  ]);

  return [visibleItems, animateIn, animationDelay];
}
