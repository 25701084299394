import React from "react";
import styled from "styled-components";
import Header from "../Leaderboard/Header";

const Layout = ({ children }) => {
  return (
    <div>
      <Header title="Board" subTitle="Administration" />
      <Box>{children}</Box>
    </div>
  );
};

export default Layout;

const Box = styled.div`
  /* back */
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 60px;
`;
