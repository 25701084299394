import React from "react";
import posed from "react-pose";

const RowLandscape = ({
  name = "name",
  score = 55,
  rank = 1,
  visible = false,
  index,
  animateIn,
  teamName,
  adminView,
  animationDelay
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1917.7 99.93">
      <defs>
        <linearGradient
          id="a-row"
          x1="959.53"
          y1="118.89"
          x2="959.53"
          y2="36.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#c6c6c6" />
          <stop offset="1" stopColor="#ededed" />
        </linearGradient>
      </defs>
      <g data-name="Layer 1">
        <TextRow
          name={name}
          animateIn={animateIn}
          index={index}
          adminView={adminView}
          teamName={teamName}
        />
        <Score
          score={score}
          animateIn={animateIn}
          index={index}
          adminView={adminView}
        />
        <Rank
          rank={rank}
          animateIn={animateIn}
          index={index}
          adminView={adminView}
        />
        <Path animateIn={animateIn} index={index} />
      </g>
    </svg>
  );
};

export default RowLandscape;

const transition = {
  transition: {
    x: { ease: "easeInOut", duration: 800 },
    default: { ease: "easeInOut", duration: 800 }
  }
};
const PathPose = posed.path({
  start: { opacity: 0, delay: ({ index }) => index * 400, transition },
  finish: { opacity: 1, delay: ({ index }) => index * 400, transition }
});

const Path = ({ score, animateIn, index, adminView }) => (
  <PathPose
    pose={!animateIn ? "start" : "finish"}
    index={index}
    opacity=".71"
    fill="url(#a-row)"
    style={{ mixBlendMode: "multiply" }}
    d="M-.47.41h1920v100.83H-.47z"
  />
);

const GRank = posed.g({
  start: {
    x: -300,
    delay: ({ index }) => index * 400,
    transition
  },
  finish: {
    x: 0,
    delay: ({ index }) => index * 400,
    transition
  }
});

const colors = {
  lightBlue: "#2aace2",
  white: "#FFF",
  darkBlue: "#2c3582"
};

const Rank = ({ rank, animateIn, index, adminView }) => (
  <GRank data-name="RANK" pose={!animateIn ? "start" : "finish"} index={index}>
    <path
      fill="#2aace2"
      fillRule="evenodd"
      d="M232.26.01H20.02v100.84h264.92L232.26.01z"
    />
    <path
      fill={adminView ? colors.white : colors.darkBlue}
      fillRule="evenodd"
      d="M210.41.01H-1.83v100.84h264.92L210.41.01z"
    />
    <text
      transform="translate(83.4 73.73)"
      fontSize="65.96"
      fill={adminView ? colors.darkBlue : colors.white}
      fontFamily="Arial"
      fontWeight="700"
    >
      {rank}
    </text>
  </GRank>
);

const GScore = posed.g({
  start: { x: 750, delay: ({ index }) => index * 400, transition },
  finish: { x: 0, delay: ({ index }) => index * 400, transition }
});

const Score = ({ score, animateIn, index, adminView }) => (
  <GScore
    data-name="SCORE"
    pose={!animateIn ? "start" : "finish"}
    index={index}
  >
    <path
      fill={adminView ? colors.white : colors.darkBlue}
      fillRule="evenodd"
      d="M1234.71 100.85h212.24V.01h-264.91l52.67 100.84z"
    />
    <path
      fill="#2aace2"
      fillRule="evenodd"
      d="M1256.56 100.85h662.97V.01h-715.65l52.68 100.84z"
    />
    <text
      transform="translate(1286.98 73.73)"
      fontSize="65.96"
      fill="#fff"
      fontFamily="Arial"
      fontWeight="700"
    >
      {score}%
    </text>
  </GScore>
);

const GText = posed.g({
  start: { x: -1570, delay: ({ index }) => index * 400, transition },
  finish: { x: 0, delay: ({ index }) => index * 400, transition }
});

const TextRow = ({ name, animateIn, index, adminView, teamName = " " }) => (
  <GText data-name="row" pose={!animateIn ? "start" : "finish"} index={index}>
    <path
      fill={adminView ? colors.darkBlue : colors.white}
      d="M20.02 0h1496.96v100.83H20.02z"
    />
    <text
      transform="translate(346.83 65.55)"
      fontSize="50.54"
      fill={adminView ? colors.white : colors.darkBlue}
      fontFamily="Arial"
      fontWeight="700"
      letterSpacing=".05em"
    >
      {name}
    </text>
    <circle cx="1148.76" cy="50.83" r="15.71" fill="#2aace2" />
    <text
      transform="translate(1139.68 60.35)"
      fontSize="29.43"
      fill={colors.white}
      fontFamily="Arial"
      letterSpacing=".26em"
    >
      {teamName.charAt(0)}
    </text>
  </GText>
);
