import React from "react";
import Layout from "./Layout";
import ReactTable from "react-table";
import "react-table/react-table.css";
import firebase from "../firebase";
import styled from "styled-components";

import "semantic-ui-css/semantic.min.css";
import { EditModal } from "./EditModal";

const INITIAL_VALUES = {
  isEditing: false,
  id: null,
  name: "",
  teamID: "",
  budget: null
};

const Admin = ({ adminView }) => {
  const [consultants, setConsultants] = React.useState([]);
  const [editing, setEditing] = React.useState(INITIAL_VALUES);

  const columns = [
    {
      Header: "Team",
      accessor: "teamName"
    },
    {
      Header: "Sales Rep",
      accessor: "name"
    },
    {
      id: "budget",
      Header: "Budget Δ",
      accessor: "budget"
    },
    {
      Header: "Edit",
      accessor: "id",
      Cell: ({ original }) => (
        <>
          <Button onClick={() => handleEditClick(original)}>EDIT</Button>
          <Button danger onClick={() => handleDeleteClick(original)}>
            DELETE
          </Button>
        </>
      )
    }
  ];

  const handleEditClick = data => {
    setEditing({ ...data, isEditing: true });
  };

  const handleDeleteClick = value => {
    firebase.db
      .doc(
        `boards/${adminView ? "account-managers" : "sales"}/consultants/${
          value.id
        }`
      )
      .delete();
  };

  const getConsultants = () =>
    firebase.db
      .collection(
        `boards/${adminView ? "account-managers" : "sales"}/consultants`
      )
      .onSnapshot(handleSnapshot);

  const handleSnapshot = snapshot => {
    const consultants = snapshot.docs.map(formatDoc);
    setConsultants(consultants);
  };

  React.useEffect(() => {
    return getConsultants();
  }, []);

  const formatDoc = doc => ({ id: doc.id, ...doc.data() });

  const handleClose = () => {
    setEditing(INITIAL_VALUES);
  };

  const handleNew = () => {
    setEditing({ ...INITIAL_VALUES, isEditing: true });
  };

  return (
    <Layout>
      <Button onClick={handleNew} success style={{ marginBottom: "20px" }}>
        New Consultant
      </Button>
      <EditModal
        isOpen={editing.isEditing}
        handleClose={handleClose}
        editing={editing}
        board={adminView ? "account-managers" : "sales"}
      />
      <ReactTable data={consultants} columns={columns} key="tble" />
    </Layout>
  );
};

export default Admin;

const Button = styled.button`
  color: ${({ danger, success }) => (danger || success ? "#FFF" : "#484848")};
  background: ${({ danger, success }) =>
    danger ? "#b35757" : success ? "#5d925d" : "#d2d2d2"};
  border-radius: 3px;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  transition: 0.2s ease-in-out all;

  &:hover {
    transform: translateX(3px);
  }
`;
