import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Row from "./Row";
import { Wrap, AdminOverlay } from "./index";
import { useAnimatedConsultantList } from "./useAnimatedConsultantList";

import sales from "../../assets/sales-landscape.jpg";
import account from "../../assets/account-landscape.jpg";

export const PortraitView = ({ adminView }) => {
  const [visibleItems, animateIn, animationDelay] = useAnimatedConsultantList(
    adminView ? "account-managers" : "sales",
    10,
    300,
    10000
  );

  return (
    <>
      {/* <Header
        title={!adminView ? "SALES" : "ACCOUNT"}
        subTitle={!adminView ? "LEADERBOARD" : "MANAGERS"}
        adminView={adminView}
      /> */}

      <img src={adminView ? account : sales} style={{ width: "100%" }} />
      <Wrap className="App">
        <AdminOverlay adminView={adminView}>
          {visibleItems.length > 0
            ? visibleItems.map((item, i) => (
                <Row
                  name={item.name}
                  score={item.budget}
                  rank={item.rank}
                  index={i}
                  teamName={item.teamName}
                  visible={false}
                  key={item.id}
                  animateIn={animateIn}
                  adminView={adminView}
                  animationDelay={animationDelay}
                />
              ))
            : null}
        </AdminOverlay>
      </Wrap>
      <Footer adminView={adminView} />
    </>
  );
};
